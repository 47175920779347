<template>
  <div>
    <div v-if="message" class="form-group alert-div">
      <div
        v-if="message && message.status == 200"
        class="alert alert-success"
        role="alert"
      >
        {{ message.message }}
      </div>
      <div v-else-if="message" class="alert alert-danger" role="alert">
        {{ message.message }}
      </div>
    </div>
    <div class="col-xs-12">
      <!--<div v-if="list" class="col-md-12 side-box">
        <div class="sheet-list-header">Arkusze</div>
        <div class="sheet-list">
          <ul class="sheet-list-element">
            <li v-for="(obj, index) in list" :key="index">
              <span style="cursor:pointer" v-on:click="editSheet(obj)">{{index+1}}. </span>
              <span style="cursor:pointer" v-on:click="editSheet(obj)">{{sheetTypesNames[obj.type]}}</span>
            </li>
          </ul>
        </div>
      </div>-->
      <div class="col-md-12 side-box" style="min-height:100vh;">
        <nav @click.prevent class="navbar navbar-expand-xl navbar-light">
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSecondNavDropdown"
            aria-controls="navbarSecondNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div
            class="collapse navbar-collapse"
            id="navbarSecondNavDropdown"
            style="z-index:9999; padding:0px !important"
          >
            <ul class="nav navbar-nav ml-auto" style="padding:0px !important">
              <li class="nav-item" @click="makeActive()" data-sheet-type="0">
                <span style="position:relative;">
                  <a
                    style="position:relative;z-index:200;"
                    href="#"
                    data-sheet-type="0"
                    @click="makeActive()"
                    >Aktywa</a
                  >
                  <div
                    v-if="sheetType == 0"
                    style="position:absolute; width:71.76px; height:10px;background-color: white; top:50%;left:23%;z-index:100;"
                  ></div>
                </span>
              </li>
              <li class="nav-item" data-sheet-type="1" @click="makeActive()">
                <span style="position:relative;">
                  <a
                    href="#"
                    style="position:relative;z-index:200;"
                    data-sheet-type="1"
                    @click="makeActive()"
                    >Pasywa</a
                  >
                  <div
                    v-if="sheetType == 1"
                    style="position:absolute; width:70.98px; height:10px;background-color: white; top:50%;left:23.5%;z-index:100;"
                  ></div>
                </span>
              </li>
              <li class="nav-item" data-sheet-type="2" @click="makeActive()">
                <span style="position:relative;">
                  <a
                    href="#"
                    style="position:relative;z-index:200;"
                    data-sheet-type="2"
                    @click="makeActive()"
                    >RZIS</a
                  >
                  <div
                    v-if="sheetType == 2"
                    style="position:absolute; width:34.53px; height:10px;background-color: white; top:50%;left:32.5%;z-index:100;"
                  ></div>
                </span>
              </li>
              <li class="nav-item" data-sheet-type="3" @click="makeActive()">
                <span style="position:relative;">
                  <a
                    href="#"
                    style="position:relative;z-index:200;"
                    data-sheet-type="3"
                    @click="makeActive()"
                    >Uzgodnienia</a
                  >
                  <div
                    v-if="sheetType == 3"
                    style="position:absolute; width:111.17px; height:10px;background-color: white; top:50%;left:18%;z-index:100;"
                  ></div>
                </span>
              </li>
              <li class="nav-item" data-sheet-type="4" @click="makeActive()">
                <span style="position:relative;">
                  <a
                    href="#"
                    style="position:relative;z-index:200;"
                    data-sheet-type="4"
                    @click="makeActive()"
                    >Szczegółowy CF</a
                  >
                  <div
                    v-if="sheetType == 4"
                    style="position:absolute; width:148.78px; height:10px;background-color: white; top:50%;left:15%;z-index:100;"
                  ></div>
                </span>
              </li>
              <li class="nav-item" data-sheet-type="5" @click="makeActive()">
                <span style="position:relative;">
                  <a
                    href="#"
                    style="position:relative;z-index:200;"
                    data-sheet-type="5"
                    @click="makeActive()"
                    >CF</a
                  >
                  <div
                    v-if="sheetType == 5"
                    style="position:absolute; width:20.95px; height:10px;background-color: white; top:50%;left:37.5%;z-index:100;"
                  ></div>
                </span>
              </li>
            </ul>
          </div>
        </nav>
        <div v-if="!currentUser || displayCreateButton" class="sheet-button">
          <a
            ref="create_sheet"
            href="#"
            :data-sheet-type="sheetType"
            @click="createSheet()"
            >Utwórz</a
          >
        </div>
        <div v-else-if="sheet" class="page">
          <div style="width: 100%; align-items: end">
            <div v-if="this.$store.state.auth.user.language === 'en'" style="margin-left: 75%; padding-bottom: 50px" @click="changeLanguage('pl')">
              Zmień język na
              <img style="width: 70px;" id="img-two" src="../assets/poland-flag-icon-256.png" alt="" />
            </div>
            <div v-else-if="this.$store.state.auth.user.language !== 'en'" style="margin-left: 75%; padding-bottom: 50px" @click="changeLanguage('en')">
              Change language to
              <img style="width: 70px;" id="img-two" src="../assets/united-kingdom-flag-icon-256.png" alt="" />
            </div>
            <!-- <SwitchLang style="margin-left: 85%" @changed="onChange" :initLang="initLang" :options="options" /> -->
          </div>
          <div :class="containerWidth">
            <div class="table-responsive-xl">
              <table
                v-if="sheet"
                class="table table-borderless"
                :class="tableWidth"
              >
                <tbody v-for="(row, index) in sheet.rows" :key="index">
                  <tr :style="rowStyle">
                    <td
                      :style="tdStyle"
                      v-for="(record, indexColumn) in row"
                      :key="indexColumn"
                      :class="{
                        'background-grey': record.attributes.includes('BGREY'),
                        'background-yellow': record.attributes.includes(
                          'CHECK'
                        ),
                        'yellow-border-bottom': record.attributes.includes(
                          'BYB'
                        ),
                        'font-bold': record.attributes.includes('FBOLD'),
                        'border-top': record.attributes.includes('BT'),
                        'border-right': record.attributes.includes('BR'),
                        'border-bottom': record.attributes.includes('BB'),
                        'border-left': record.attributes.includes('BL'),
                        'border-bottom-double': record.attributes.includes(
                          'BDB'
                        ),
                        'border-top-double': record.attributes.includes('BDT'),
                        'border-bottom-margin': record.attributes.includes(
                          'BMB'
                        ),
                        'border-top-margin': record.attributes.includes('BMT'),
                        'text-roman': record.attributes.includes('ROMAN'),
                        'text-number': record.attributes.includes('NUMBER'),
                        'text-capital': record.attributes.includes('CAP'),
                        'error-value':
                          record.attributes.includes('CHECK') &&
                          record.value != '0.00',
                        'text-center':
                          record.recordType === 'number' ||
                          (record.rowPosition === 1 &&
                            record.columnPosition != 1),
                        'background-highlight':
                          selectedRow == record.rowPosition,
                        'style-italics': record.attributes.includes('ITA'),
                        'align-center': record.attributes.includes('VAM'),
                      }"
                    >
                      <div
                        v-if="record.attributes.includes('DATE-OB')"
                        class="classOb"
                      >
                        {{getString('OB')}}
                      </div>
                      <div
                        v-if="record.attributes.includes('DATE-CB')"
                        class="classCb"
                      >
                        {{getString('CB')}}
                      </div>
                      <input
                        v-if="!record.readOnly"
                        v-model="record.value"
                        type="text"
                        @focus="onFocusText($event, record)"
                        @blur="onBlurNumber($event, record)"
                        :recordType="record.recordType"
                        :disabled="record.readOnly"
                        oninput="this.value =
                      this.value.replace(/[^0-9.-]/g, '').replace(/(\..*)\./g,
                      '$1').replace(/(?<!^)-/g, '')"
                      />
                      <template v-else>
                        {{ getString(isNegative(record.value)) }}
                      </template>
                    </td>
                  </tr>
                  <tr
                    v-if="
                      sheet.sheetType == '3' &&
                        row[0].attributes.includes('BMB')
                    "
                    style="height:7px; background-color:white"
                  ></tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="sheet-button bottom-buttons">
            <a
              class="btn-yellow"
              ref="save_sheet"
              href="#"
              @click="saveSheet($event)"
              >Zapisz</a
            >
            <a
              class="btn-black"
              ref="download_sheet"
              href="#"
              @click="downloadSheet()"
              >Pobierz</a
            >
            <!--<a href="#" ref="delete_sheet" v-on:click="deleteSheet()">Usuń</a>-->
          </div>
        </div>
        <div v-else class="spinner-div">
          <span class="spinner-border spinner-border-lg"></span>
        </div>
      </div>
      <!--<div class="col-md-3 side-box side-menu">
        <ul>
          <li>
            <a href="#" v-on:click="newSheet()">Nowy arkusz</a>
          </li>
          <li>
            <a href="#" v-on:click="listSheet()">Moje arkusze</a>
          </li>
        </ul>
      </div>-->
    </div>
  </div>
</template>

<script>
import UserService from "../services/user.service";
import Sheet from "../models/sheet";
import SheetDto from "../models/sheetDto";
import shared from "../shared";
import {getString, initLanguage} from "../locales/strings";
import SwitchLang from "vue-switch-lang";

export default {
  name: "Sheet",
  components: {
    SwitchLang
  },
  data() {
    return {
      loading: false,
      message: null,
      sheet: null,
      sheetType: 0,
      containerWidth: "container",
      tableWidth: "",
      rowStyle: "",
      tdStyle: "",
      selectedRow: "",
      list: null,
      displayCreateButton: false,
      sheetTypesNames: [
        "Aktywa",
        "Pasywa",
        "RZIS",
        "Uzgodnienia",
        "Szczegółowy CF",
        "CF",
      ],
      initLang: {
        title: "en",
        flag: "gb"
      },
      options: [
        {
          title: "en",
          flag: "gb"
        },
        {
          title: "pl",
          flag: "pl"
        },
      ]
    };
  },
  watch: {
    type() {
      this.changeTableWidth();
    },
    newSheet() {
      if (this.sheet) {
        this.replaceSheetValues();
      }
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    type() {
      return this.sheetType;
    },
    newSheet() {
      return this.sheet;
    },
  },
  created() {
    this.isCapital = shared.validateCapital;
    this.isSmall = shared.validateSmall;
    this.isNumber = shared.validateNumber;
    this.isRoman = shared.validateRoman;
    this.isNegative = shared.validateNegative;
    this.insertSpace = shared.numbersWithSpaces;
    this.getString = getString;
  },
  mounted() {
    if (this.$store.state.auth.user) this.createSheet();
    console.log(this.$store.state.auth.user)
    console.log(this.$store.state.user)

    if (this.$store.state.auth.user && this.$store.state.auth.user.language) {
      initLanguage(this.$store.state.auth.user.language)
    } else {
      initLanguage('pl')
    }
  },
  methods: {
    makeActive: function() {
      this.message = null;
      if (this.sheetType != event.target.getAttribute("data-sheet-type")) {
        this.sheetType = event.target.getAttribute("data-sheet-type");
        this.sheet = null;
        this.createSheet();
      }
    },
    createSheet() {
      if (!this.currentUser) {
        this.$router.push("/login?signin=0");
      } else {
        this.message = null;
        this.loading = true;
        UserService.getNewSheet(this.sheetType).then(
          (response) => {
            var content = response.data;
            if (
              content["paymentStatus"] &&
              content["paymentStatus"] == "pending"
            ) {
              if (this.displayCreateButton) this.$router.push("/payment");
              else this.displayCreateButton = true;
            } else {
              this.sheet = new Sheet(
                content.id,
                content.sheetType,
                content.maxColumnNumber,
                content.recordDtoList
              );
            }
          },
          (error) => {
            this.message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
            setTimeout(() => (this.message = ""), 2000);
          }
        );
        this.loading = false;
      }
    },
    saveSheet(e) {
      e.preventDefault();
      this.message = null;
      var recordDtoList = new SheetDto(
        this.sheet.id,
        this.sheet.sheetType,
        this.sheet.maxColumnNumber,
        this.sheet.rows
      );
      UserService.updateSheet(recordDtoList).then(
        (response) => {
          this.message = {
            status: response.status,
            message: "Arkusz został zapisany",
          };
          setTimeout(() => (this.message = ""), 2000);
          this.editSheet({ id: this.sheet.id, type: this.sheet.sheetType });
        },
        (error) => {
          this.message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          setTimeout(() => (this.message = ""), 2000);
        }
      );
    },
    downloadSheet() {
      UserService.downloadSheet(this.sheet.id).then(
        (response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "arkusz");
          document.body.appendChild(link);
          link.click();
        },
        (error) => {
          this.message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    getColumnClass(record) {
      if (
        record.recordType === "number" ||
        (record.rowPosition === 1 && record.columnPosition != 1)
      ) {
        return "text-center";
      }
      if (record.columnPosition === 1 && this.isCapital(record.value)) {
        return "text-left";
      } else if (record.columnPosition === 1 && this.isRoman(record.value)) {
        return "text-roman";
      } else if (record.columnPosition === 1 && this.isNumber(record.value)) {
        return "text-number";
      } else if (record.columnPosition === 1 && this.isSmall(record.value)) {
        return "text-small";
      }
    },
    onBlurNumber(e, record) {
      this.selectedRow = "";
      if (e.target.attributes.recordtype.value === "number") {
        if (e.target.value !== "") {
          this.sheet.rows[record.rowPosition - 1][
            record.columnPosition - 1
          ].value = this.thousandSeprator(
            parseFloat(e.target.value).toFixed(2)
          );
        } else {
          this.sheet.rows[record.rowPosition - 1][
            record.columnPosition - 1
          ].value = "0.00";
        }
        return;
      }
    },
    onFocusText(e, record) {
      if (e.target.attributes.recordtype.value === "number") {
        var val = e.target.value.replace(/[,]/g, "");
        this.sheet.rows[record.rowPosition - 1][
          record.columnPosition - 1
        ].value = val;
      }
      this.selectedRow = record.rowPosition;
    },
    thousandSeprator(amount) {
      if (
        amount !== "" ||
        amount !== undefined ||
        amount !== 0 ||
        amount !== "0" ||
        amount !== null
      ) {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return amount;
      }
    },
    editSheet(data) {
      this.list = null;
      this.sheetType = data.type;

      UserService.getSheet(data.id).then(
        (response) => {
          var content = response.data;
          this.sheet = new Sheet(
            content.id,
            content.sheetType,
            content.maxColumnNumber,
            content.recordDtoList
          );
        },
        (error) => {
          this.message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    changeTableWidth() {
      console.log(this.sheetType);
      if (this.sheetType == 4 || this.sheetType == 5) {
        this.containerWidth = "container d-flex justify-content-center";
        this.tableWidth = "cf-table";
        this.rowStyle = "";
        this.tdStyle = "";
      } else if (this.sheetType == 3) {
        this.containerWidth = "container-fluid";
        this.tableWidth = "text-center";
        this.tdStyle = "padding:2px 5px 2px 5px;";
        this.rowStyle = "font-size: 0.95rem;";
      } else if (this.sheetType == 2) {
        this.containerWidth = "container d-flex justify-content-center";
        this.tableWidth = "cf-table";
        this.rowStyle = "";
        this.tdStyle = "";
      } else {
        this.containerWidth = "container";
        this.tableWidth = "";
        this.rowStyle = "";
        this.tdStyle = "";
      }
    },
    replaceSheetValues() {
      var i;
      var j;
      for (i = 0; i < this.sheet.rows.length; i++) {
        for (j = 0; j < this.sheet.rows[i].length; j++) {
          if (
            this.sheet.rows[i][j].recordType == "number" &&
            this.sheet.rows[i][j].value != ""
          ) {
            this.sheet.rows[i][j].value = this.thousandSeprator(
              parseFloat(this.sheet.rows[i][j].value).toFixed(2)
            );
          }
          if (
            this.sheet.rows[i][j].recordType == "number" &&
            this.sheet.rows[i][j].value != "" &&
            this.sheet.rows[i][j].readOnly == true
          ) {
            this.sheet.rows[i][j].value = this.insertSpace(
              this.sheet.rows[i][j].value
            );
          }
        }
      }
    },
    changeLanguage(lang) {
      console.log('new language: ' + JSON.stringify(lang))
      initLanguage(lang)
      this.$store.dispatch("auth/setUserLanguage", lang).then(() => {
        //this.mapVuexToData();
      });
    }
  },
};
</script>
<style lang="scss" scoped>
$green: rgb(255, 247, 0);
$dark-green: #125421;
.side {
  &-box {
    float: right;
  }

  &-menu {
    li {
      display: block;
      background-color: $green;
      box-shadow: 0 1px 1px #ccc;
      border-radius: 2px;

      &:hover {
        background-color: $dark-green;
      }
    }
  }
}

.sheet {
  &-button {
    display: block;
    margin: 60px auto 45px;
    text-align: center;

    a {
      max-width: 130px;
      background-color: $green;
      box-shadow: 0 1px 1px #ccc;
      border-radius: 2px;
      display: inline-block;
      margin: 0 5px;

      &:hover {
        background-color: $dark-green;
      }
    }
  }

  &-table {
    border-collapse: collapse;

    td,
    th {
      border: 2px solid #999;
      padding: 10px;
      text-align: left;
    }

    input {
      width: 100%;
      margin: 0px;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      border: none;
    }
  }

  &-readonly {
    background-color: #ebebe4;
  }

  &-list {
    margin: 0 auto;
    background-color: #e6e6e6;

    &-element {
      list-style-type: none;
      padding: 0 20px;
      li {
        padding: 5px;
        &:hover {
          background-color: $green;
        }
      }
    }

    &-header {
      display: block;
      margin: 60px auto 45px;
      background-color: $green;
      box-shadow: 0 1px 1px #ccc;
      border-radius: 2px;
      text-align: center;
      padding: 18px 30px;
      color: #fff !important;
      font-weight: bold;
      font-size: 16px;
      line-height: 1;
      text-transform: uppercase;
    }
  }
}

nav {
  position: absolute;
  top: 0px;
  left: 0;
  background-color: $green;
  width: 100%;
  text-align: right;
}

a {
  display: inline-block;
  padding: 18px 30px;
  color: black !important;
  font-weight: bold;
  font-size: 16px;
  text-decoration: none !important;
  line-height: 1;
  text-transform: uppercase;
  background-color: transparent;

  -webkit-transition: background-color 0.25s;
  -moz-transition: background-color 0.25s;
}
.alert-div {
  text-align: center;
}

span {
  font-size: 16x;
}

.spinner-div {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.alert {
  margin: 0px;
}
.alert-div {
  margin: 0px;
}
.btn-black {
  background-color: black !important;
  color: yellow !important;
}
a.btn-yellow:hover {
  background-color: #fff700 !important;
}
a.btn-black:hover {
  background-color: black !important;
}
.page {
  padding-top: 50px;
}
.side-box {
  background-color: #f7f7f7;
  padding: 0px !important;
}
input {
  border: none;
  text-align: center;
}
.grey-row {
  background-color: #e8e8e8 !important;
  font-weight: bold;
}
.text-roman {
  padding-left: 120px;
}
.text-number {
  padding-left: 180px;
}
.text-capital {
  padding-left: 60px;
}
.container-table {
  width: 70%;
}
.cf-table {
  width: 830px;
}
.error-value {
  font-weight: bold;
  color: red;
}

.yellow-border-bottom {
  border-bottom: 10px solid #fff700 !important;
}
.font-bold {
  font-weight: bold;
}
.background-grey {
  background-color: #e8e8e8 !important;
}
.border-bottom {
  border-bottom: 1px solid black !important;
}
.border-top {
  border-top: 1px solid black !important;
}
.border-left {
  border-left: 1px solid black !important;
}
.border-right {
  border-right: 1px solid black !important;
}
.border-bottom-double {
  border-bottom: 3px double black !important;
}
.border-top-double {
  border-top: 3px double black !important;
}
.border-bottom-margin {
  border-bottom: 1px solid black !important;
}
.border-top-margin {
  border-top: 1px solid black !important;
}
.background-yellow {
  background-color: #fff700;
}
.bottom-buttons {
  max-width: 270px;
  position: sticky;
  display: inherit;
  bottom: 5%;
  left: 50;
}
input:focus {
  outline-color: #fff700;
  -webkit-box-shadow: 0px 0px 30px -8px rgb(225, 255, 0);
  -moz-box-shadow: 0px 0px 30px -8px rgb(225, 255, 0);
  box-shadow: 0px 0px 30px -8px rgb(225, 255, 0);
}
.table td {
  border-top: 0px;
}
.background-highlight {
  background-color: rgb(255, 255, 125) !important;
}
.classOb {
  transform: translateX(9px);
  font-size: 0.8rem;
  font-weight: bold;
}
.classCb {
  transform: translateX(8px);
  font-size: 0.8rem;
  font-weight: bold;
}
.style-italics {
  font-style: italic;
}
.navbar-expand-xl {
  padding: 0px 0px 0px 30px !important;
  z-index: 9000 !important;
}
nav {
  position: relative !important;
  width: 100% !important;
}
.nav-item {
  cursor: pointer;
}
.align-center {
  vertical-align: middle;
}
.multiselect__option--selected {
  background-color: red;
}

.multiselect__option--highlight {
  background-color: green;
  background: green;
}
.multiselect__tags {
  background-color: green;

}
</style>
