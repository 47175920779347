export const pl = {
  text: {
    "CB": "BZ",
    "OB": "BO",
    "[CB]-[OB]": "[BZ]-[BO]",
    "[CB] XXXX": "[BZ] XXXX",

    "ASSETS": "AKTYWA",
    "Fixed Assets": "Aktywa trwałe",
    "Intangible Assets": "Wartości niematerialne i prawne",
    "Property, Plant and Equipment": "Rzeczowe aktywa trwałe",
    "Real Estate": "Nieruchomości",
    "Investments": "Inwestycje",
    "Deferred Income Tax": "Aktywa z tytułu odroczonego podatku dochodowego",
    "Currents Assets": "Aktywa obrotowe",
    "Inventory": "Zapasy",
    "Receivables": "Należności",
    "Loans": "Udzielone pożyczki",
    "Cash": "Środki pieniężne",
    "Prepaid Expenses": "Rozliczenia międzyokresowe czynne",
    "TOTAL ASSETS": "AKTYWA RAZEM",

    "LIABILITIES": "PASYWA",
    "Equity": "Kapitał własny",
    "Share Capital": "Kapitał podstawowy",
    "Revaluation Capital": "Kapitał z aktualizacji wyceny",
    "Retaned Earnings": "Zysk/strata z lat ubiegłych",
    "Net Profit (Loss)": "Zysk/strata netto",
    "Liabilities": "Zobowiązania",
    "Deffered Income Tax": "Rezerwa z tytułu odroczonego podatku dochodowego",
    "Reserves": "Rezerwy",
    "Credits": "Kredyty i pożyczki",
    "Lease": "Zobowiązania z tytułu leasingów",
    "Trade Liabilities": "Zobowiązania z tytułu dostaw i usług",
    "Tax Liabilities": "Zobowiązania z tytułu podatku dochodowego",
    "Others": "Pozostałe zobowiązania",
    "Accruals": "Rozliczenia międzyokresowe",
    "TOTAL LIABILITIES": "PASYWA RAZEM",
    "CONTROL": "Sprawdzenie",

    "Income Statement": "Rachunek zysków i strat",
    "A. Income": "A. Przychody netto ze sprzedaży",
    "B. Operating Expenses": "B. Koszty działalności operacyjnej",
    "C. Profit (Loss) on Sales (A-B)": "C. Zysk (strata) ze sprzedaży (A-B)",
    "D. Other Operating Income": "D. Pozostałe przychody operacyjne",
    "E. Other Operating Costs": "E. Pozostałe koszty operacyjne",
    "F. Profit (Loss) from Operations (C+D+E)": "F. Zysk (strata) z działalności operacyjnej (C+D+E)",
    "G. Financial Revenue": "G. Przychody finansowe",
    "I. Dividends": "I. Dywidendy",
    "II. Interests": "II. Odsetki",
    "III. Profit on Disposal of Financial Assets": "III. Zysk z tytułu rozchodu aktywów finansowych",
    "IV. Revaluation of Financial Assets": "IV. Aktualizacja wartości aktywów finansowych",
    "V. Others": "V. Inne",
    "H. Financial Costs": "H. Koszty finansowe",
    "I. Interests": "I. Odsetki",
    "II. Loss on Disposal of Financial Assets": "II. Strata z tytułu rozchodu aktywów finansowych",
    "III. Revaluation of Financial Assets": "III. Aktualizacja wartości aktywów finansowych",
    "IV. Others": "IV. Inne",
    "I. Profit (Loss) Gross (F+G-H)": "I. Zysk (strata) brutto (F+G-H)",
    "J. Income Tax": "J. Podatek dochodowy",
    "K. Net Profit (Loss) (I-J)": "K. Zysk (strata) netto (I-J)",

    "Check": "Kontrola",
    "Purchases": "Zakup",
    "Sale": "Sprzedaż",
    "Valuation updates": "Wycena",
    "Depreciation": "Amortyzacja",
    "Depreciation Released on Disposal": "Zmniejszenie umorzenia",
    "Sale of Intangible Assets": "Wartości niematerialne i prawne",
    // "Property, Plant and Equipment": "Środki trwałe",
    "Sale of Property, Plant and Equipment": "Przychód ze sprzedaży Środków trwałych",
    "Deffered Tax": "Odroczony",
    "Current Tax": "Bieżący",
    "Tax": "Podatek dochodowy",
    "Interest paid": "Odsetki zapłacone",
    "Accrued Interest": "Odsetki naliczone",
    "Granting": "Zwiększenia",
    "Sale of Investment": "Przychód ze sprzedaży Inwestycji",
    "Sale of Real Estate": "Przychód ze sprzedaży Nieruchomości",
    "Purchase": "Zakup",
    "Repayments": "Spłaty",
    "Dividend": "Dywidendy wypłacone",
    "Accrued interest": "Odsetki naliczone",
    "Interest received": "Odsetki otrzymane",
    "Surcharges, Issues and redemption of debt securities": "Dopłaty, emisje i wykup dłużnych papierów wartościowych",
    "Capital Increases": "Zwiększenie kapitału",

    "Cash Flow Statement": "Rachunek przepływów pieniężnych",
    "A. Cash Flows From Operating Activities": "A. Przepływy środków pieniężnych z działalności operacyjnej",
    "I. Gross Profit (Loss)": "I. Zysk (strata) brutto",
    "Net Profit (Loss)": "Zysk (strata) netto",
    "Current Tax": "Podatek dochodowy (część bieżąca)",
    "Deffered Tax": "Podatek dochodowy (część odroczona)",
    "II. Total Adjustments": "II. Korekty razem",
    "1. Depreciation": "1. Amortyzacja",
    "Intangible Assets": "Wartości niematerialne i prawne",
    "2. Profit (Loss) due to Exchange Differences": "2. Zyski (straty) z tytułu różnic kursowych ",
    "Valuation updates of Loans": "Wycena pożyczek udzielonych",
    "Valuation updates of Credits": "Wycena kredytów i pożyczek",
    "Valuation updates of Lease": "Wycena leasingów",
    "3. Interest and Share in Profits (Dividends)": "3. Odsetki i udziały w zyskach (dywidendy)",
    "Interest accrued on Loans": "Odsetki naliczone od pożyczek udzielonych",
    "Interest accrued on Credits": "Odsetki naliczone od kredytów i pożyczek",
    "Interest accrued on Lease": "Odsetki naliczone od leasingów",
    "4. Profit (Loss) on Investment Activities": "4. Zysk (strata) z działalności inwestycyjnej",
    "Result on the sale of Intangible Assets": "Sprzedaż Wartości niematerialnych i prawnych",
    "Result on the sale of  Intangible Assets": "Sprzedaż Wartości niematerialnych i prawnych",
    "Result of valuation updates of Intangible Assets": "Wycena Wartości niematerialnych i prawnych",
    "Result on the sale of Property, Plant and Equipment": "Sprzedaż Środków trwałych",
    "Result on the valuation updates of Property, Plant and Equipment": "Wycena Środków trwałych",
    "Result on the sale of Real Estate": "Sprzedaż Nieruchomości",
    "Result on the valuation updates of Real Estate": "Wycena Nieruchomości",
    "Result on the sale of Investments": "Sprzedaż Inwestycji",
    "Result on the valuation updates of Investments": "Wycena Inwestycji",
    "Revaluation reserve": "Kapitał z aktualizacji wyceny",
    "5. Change in Reserves": "5. Zmiana stanu rezerw",
    "6. Change in Inventories": "6. Zmiana stany zapasów",
    "7. Change in Receivables": "7. Zmiana stanu należności",
    "8. Change in Trade Liabilities": "8. Zmiana stanu zobowiązań",
    "9. Change in Prepaid Expenses and Accruals": "9. Zmiana stanu rozliczeń międzyokresowych",
    "Change in Prepaid Expenses": "Rozliczenia międzyokresowe czynne",
    "Change in Accruals": "Rozliczenia międzyokresowe bierne",
    "10. Income Tax": "10. Podatek dochodowy",
    "Current Tax": "Podatek bieżący",
    "Change in Tax Liabilities": "Zmiana stanu odroczonego podatku dochodowego",
    "III. Net Cash Flow from Operating Activities (I+II)": "III. Przepływy pieniężne netto z działalności operacyjnej (I+II)",
    "B. Cash Flows From Investment Activities": "B. Przepływy środków pieniężnych z działalności inwestycyjnej",
    "1. Sale of Intangible Assets and Property, Plant and Equipment": "1. Zbycie Wartości niematerialnych i prawnych i Środków trwałych",
    "4. Dividends": "4. Dywidendy",
    "Dividends": "Dywidendy",
    "Disposal of Property, Plant and Equipment": "Środki trwałe",
    "2. Sale of Real Estate": "2. Zbycie Nieruchomości",
    "3. Disposal of Investments": "3. Zbycie Inwestycji",
    "5. Interests": "5. Odsetki",
    "6. Loans": "6. Pożyczki",
    "II. Expenses": "II. Wydatki",
    "1. Purchase of Intangible Assets and Property, Plant and Equipment": "1. Nabycie Wartości niematerialnych i prawnych i Środków trwałych",
    "Purchase of Intangible Assets": "Wartości niematerialne i prawne",
    "Purchase of Property, Plant and Equipment": "Środki trwałe",
    "Leases": "Leasingi",
    "2. Investments in Real Estate": "2. Nabycie Nieruchomości",
    "3. Acquisition of Investments": "3. Nabycie Inwestycji",
    "4. Loans": "4. Pożyczki udzielone",
    "III. Net Casf Flow from Investing Activities (I-II)": "III. Przepływy pieniężne netto z działalności inwestycyjnej (I-II)",
    "C. Cash Flows From Financial Activities": "C. Przepływy środków pieniężnych z działalności finansowej",
    "I. Incomes": "I. Wpływy",
    "1. Net Income from The Issue of Shares and Other Capital Instruments and Capital Surcharges": "1. Wpływy netto z emisji akcji i innych instrumentów kapitałowych oraz dopłat kapitałowych",
    "2. Credits": "2. Kredyty",
    "3. Issue of Debt Securites": "3. Emisja dłużnych papierów wartościowych",
    "4. Other Financial Incomes": "4. Inne wpływy finansowe",
    "1. Dividends": "1. Dywidendy",
    "2. Credits": "2. Kredyty",
    "3. Purchase of Debt Securites": "3. Zakup dłużnych papierów wartościowych",
    "4. Payments under Finance Leases": "4. Płatności zobowiązań z tytułu umów leasingu finansowego",
    "Interest paid on Credits": "Kredyty i pożyczki",
    "Interest paid on Leases": "Leasingi",
    "6. Other Financial Expenses": "6. Inne wydatki inwestycyjne",
    "III. Net Cash Flow from Financing Activities (I-II)": "III. Przepływy pieniężne netto z działalności finansowej (I-II)",
    "D. Total Net Cash Flows (A.III+B.III+C.III)": "D. Przepływy pieniężne netto razem (A.III+B.III+C.III)",
    "E. Balance Sheet of Cash": "E. Bilansowa zmiana stanu środków pieniężnych",
    "F. Cash at The Beginning of The Period": "F. Środki pieniężne na początek okresu",
    "G. Cash at The End of The Period (F+D)": "G. Środki pieniężne na koniec okresu (F+D)",
    "CONTROL": "Sprawdzenie",
    "": "",
    "": "",
    "": "",
    "": "",
    "": "",
    "": "",

  }
}
