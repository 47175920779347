import Record from "./record";

export default class Sheet {
  constructor(id, type, maxColumnNumber, recordList) {
    this.id = id;
    this.sheetType = type;
    this.maxColumnNumber = maxColumnNumber;
    this.rows = [];

    var i;
    var colCount = 1;
    var rowCount = 1;
    var record;
    var row = [];
    for (i = 0; i < recordList.length; i++) {
      if (
        recordList[i].rowPosition == rowCount &&
        recordList[i].columnPosition == colCount
      ) {
        record = new Record(
          // recordList[i].id,
          recordList[i].rowPosition,
          recordList[i].columnPosition,
          recordList[i].recordType,
          recordList[i].value,
          recordList[i].readOnly,
          recordList[i].attributes
        );
      } else {
        record = new Record(
          // null,
          rowCount,
          colCount,
          "NUMBER",
          "",
          false
        );
      }

      row.push(record);
      colCount++;
      if (colCount > this.maxColumnNumber) {
        colCount = 1;
        rowCount++;
        this.rows.push(row);
        row = [];
      }
      // this.recordList.push(record);
    }
    // this.rowCount = rowCount - 1;
  }
}
