export default class Record {
  constructor(
    rowPosition,
    columnPosition,
    recordType,
    value,
    readOnly,
    attributes
  ) {
    // this.id = id;
    this.rowPosition = rowPosition;
    this.columnPosition = columnPosition;
    this.recordType = recordType;
    this.value = value;
    this.readOnly = readOnly;
    this.attributes = attributes;
  }
}
