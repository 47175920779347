import Record from "./record";

export default class SheetDto {
  constructor(id, type, maxColumnNumber, rows) {
    this.id = id;
    this.sheetType = type;
    this.maxColumnNumber = maxColumnNumber;
    this.recordDtoList = [];

    var i = 0;
    var j = 0;
    var record = null;
    for (i = 0; i < rows.length; i++) {
      for (j = 0; j < maxColumnNumber; j++) {
        if (rows[i][j].recordType == "number") {
          record = new Record(
            rows[i][j].rowPosition,
            rows[i][j].columnPosition,
            rows[i][j].recordType,
            rows[i][j].value.replaceAll(",", ""),
            rows[i][j].readOnly,
            rows[i][j].attributes
          );
        } else {
          record = new Record(
            rows[i][j].rowPosition,
            rows[i][j].columnPosition,
            rows[i][j].recordType,
            rows[i][j].value,
            rows[i][j].readOnly,
            rows[i][j].attributes
          );
        }

        this.recordDtoList.push(record);
      }
    }
  }
}
