// /* eslint-disable */
// // import {en} from './en';
import {pl} from './pl';
//
let defaultLanguage = "pl";
let area = 'text';

let type = 'pl';
let language = null;
export const initLanguage = name => {
  language = name;
};
//
// export const saveLanguage = name => {
//   language = name;
//   // async function asyncCall() {
//   //   await AsyncStorage.setItem('language', name);
//   // }
//   // asyncCall();
// };
export const getString = (stringName) => {
  if (!language) {
    language = defaultLanguage;
  }
  if (language === 'pl' && pl[area][stringName]) {
    return pl[area][stringName];
  }
  return stringName;
}
// export const getString = <AreaName extends keyof typeof pl>(
//   stringName: keyof typeof pl[AreaName]
// ): string => {
//   console.log(this.$store.state.user.user)
//   if (!language) {
//     language = defaultLanguage;
//   }
//   if (language === 'pl') {
//     return pl[area][stringName];
//   }
//   return pl[area][stringName];
//   // if (language === 'en') {
//   //   return en[area][stringName];
//   // }
// };
//
// // Plurals for polish language
// export const handlePlurals = (
//   singularNominativ: string,
//   pluralNominativ: string,
//   pluralGenitive: string,
//   value: number,
// ): string => {
//   value = Math.abs(value);
//   if (value === 1) {
//     return singularNominativ;
//   } else if (
//     value % 10 >= 2 &&
//     value % 10 <= 4 &&
//     (value % 100 < 10 || value % 100 >= 20)
//   ) {
//     return pluralNominativ;
//   } else {
//     return pluralGenitive;
//   }
// };
//
// export type ErrorMessage = keyof typeof pl['error'] | '';
// export type FieldMessage = keyof typeof pl['fields'];
